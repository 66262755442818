import MimoWebLogo from '@components/icons/mimoWebLogo';
import Link from 'next/link';
import {
  BodyText,
  Heading5,
  Caption,
  LinkText,
} from '@components/web-landingpage/Texts';
import TwitterLandingPageFooterIconComponent from '@components/icons/twitterLandingPageFooterIcon';
import FacebookLandingPageFooterIconComponent from '@components/icons/facebookLandingPageFooterIcon';
import InstagramLandingPageFooterIconComponent from '@components/icons/instagramLandingPageFooterIcon';
import TiktokLandingPageFooterIconComponent from '@components/icons/tiktokLandingPageFooterIcon';
import LinkedInLandingPageFooterIconComponent from '@components/icons/linkedInLandingPageFooterIcon';
import MediumLandingPageFooterIconComponent from '@components/icons/mediumLandingPageFooterIcon';
import { FooterConfetti } from './ConfettiBackground';
import { ABOUT_US_LINK, JOBS_LINK } from '@utils/constants';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  MEDIUM_LINK,
  TIKTOK_LINK,
  TWITTER_LINK,
} from '@utils/constants';
import MascotSittingIconComponent from '@components/illustrations/MascotSitting';

export default function FooterComponent({ className }: { className?: string }) {
  return (
    <div
      className={`relative z-10 w-full bg-marketing-background-secondary text-marketing-text-primary ${className}`}
    >
      <FooterConfetti />
      <div className="relative m-auto w-full max-w-[1364px] space-y-14 px-6 py-16 tablet:px-10">
        <div className="space-y-6">
          <Link href="/">
            <MimoWebLogo className="h-8" width="100" height="28" />
          </Link>
          <BodyText className="w-full">You can code, too.</BodyText>
        </div>
        <div className="grid grid-flow-row-dense grid-cols-2 justify-between gap-5 gap-y-10 tablet:grid-cols-4">
          <div className="flex flex-col gap-4">
            <Heading5 className="mb-2">Company</Heading5>
            <Link href={ABOUT_US_LINK} target="_blank">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Careers
              </LinkText>
            </Link>
            <Link href="/blog">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Blog
              </LinkText>
            </Link>
            <Link href={JOBS_LINK} target="_blank">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Jobs
              </LinkText>
            </Link>
          </div>
          <div className="col-start-1 flex flex-col gap-4 tablet:col-start-2">
            <Heading5 className="mb-2">Product</Heading5>
            {/* FIXME: TEMP REMOVE LINK TO PRO PAGE */}
            {/* <Link href="/pro">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Mimo Pro
              </LinkText>
            </Link> */}
            {/* FIXME: TEMP REMOVE LINK TO GIFTCARDS PAGE */}
            {/* <Link href="/giftcards">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Gift Cards
              </LinkText>
            </Link> */}
            <Link href="/glossary/html/attributes">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Glossary
              </LinkText>
            </Link>
            <Link href="/terms">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Terms of Use
              </LinkText>
            </Link>
            <Link href="/privacy">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Privacy Policy
              </LinkText>
            </Link>
          </div>
          <div className="col-start-2 flex flex-col gap-4 tablet:col-start-3">
            <Heading5 className="mb-2">Useful</Heading5>
            <Link href="/press-kit">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Press Kit
              </LinkText>
            </Link>
            <Link href="/legal">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Imprint
              </LinkText>
            </Link>
            <Link href="https://getmimo.zendesk.com/hc/en-us">
              <LinkText className="hover:text-marketing-button-primary-hover">
                Help
              </LinkText>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <Heading5 className="mb-2">Follow us</Heading5>
            <div className="flex max-w-[160px] flex-wrap gap-6 tablet:gap-5">
              <a
                title="Link to Twitter"
                target="_blank"
                rel="noopener noreferrer"
                href={TWITTER_LINK}
                aria-label="Twitter"
              >
                <TwitterLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></TwitterLandingPageFooterIconComponent>
              </a>
              <a
                title="Link to Facebook"
                target="_blank"
                rel="noopener noreferrer"
                href={FACEBOOK_LINK}
                aria-label="facebook"
              >
                <FacebookLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></FacebookLandingPageFooterIconComponent>
              </a>
              <a
                title="Link to Instagram"
                target="_blank"
                rel="noopener noreferrer"
                href={INSTAGRAM_LINK}
                aria-label="Instagram"
              >
                <InstagramLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></InstagramLandingPageFooterIconComponent>
              </a>
              <a
                title="Link to TikTok"
                target="_blank"
                rel="noopener noreferrer"
                href={TIKTOK_LINK}
                aria-label="TikTok"
              >
                <TiktokLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></TiktokLandingPageFooterIconComponent>
              </a>
              <a
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
                href={LINKEDIN_LINK}
                aria-label="LinkedIn"
              >
                <LinkedInLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></LinkedInLandingPageFooterIconComponent>
              </a>
              <a
                title="Link to Medium"
                target="_blank"
                rel="noopener noreferrer"
                href={MEDIUM_LINK}
                aria-label="Medium"
              >
                <MediumLandingPageFooterIconComponent className="h-6 hover:text-marketing-button-primary-hover"></MediumLandingPageFooterIconComponent>
              </a>
            </div>
          </div>
        </div>
        <Caption className="text-marketing-text-secondary">
          © {new Date().getFullYear()} Mimo GmbH
        </Caption>
        <MascotSittingIconComponent className="absolute bottom-0 right-10 h-24 tabletLg:right-40 tabletLg:h-40"></MascotSittingIconComponent>
      </div>
    </div>
  );
}
